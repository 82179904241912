import axios from 'axios'
import moment from 'moment'
import store from '../store'
import { getSession } from '../helpers/session'

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

httpClient.interceptors.request.use(config => {
  const { token, login_at } = getSession()

  config.headers.Authorization =  token ? `Bearer ${token}` : '';
 // console.log(token);
  const expired = moment().diff(moment(login_at), 'hours') >= 12
  if (expired) {
    store.commit('setError', { 
      status: 401, 
      message: 'Session Expired. Please login again.' 
    })
    return config
  }
  // const body = !config.data
  //   ? { token }
  //   : { ...config.data, token }

  const body = config.data

  return { ...config, data: body }
})

httpClient.interceptors.response.use(
  res => res,
  err => {
    if (!err.response) {
      store.commit('setError', {
        status: 500,
        message: 'Cannot connect to server. Please try agin later.'
      })
      return Promise.reject(err)
    }

    const { status } = err.response

    if (status === 401) {
      store.commit('setError', {
        status: 401,
        message: 'Session Expired. Please login again.',
      })
    }

    return Promise.reject(err)
  }
)

export {
  httpClient,
}
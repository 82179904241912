// import { httpClient } from '../../api'
// import { mockPostData} from '../../api/mock'
// import {articles as articleData } from '../../api/mock/data/articles.json'
import articleData from '../../api/mock/data/articles.json'
import postsData from '../../api/mock/data/posts.json'

const defaultState = {
  trendPosts: [],
  articles: [],
  trendCount: 0,
  count: 0,
  trendPage: 1,
  page: 0,
}

export default {
  namespaced: true,

  state: {
    trendPosts: [],
    articles: [],
    trendCount: 0,
    count: 0,
    trendPage: 1,
    page: 0,
    category: undefined,
  },

  mutations: {
    setTrendPosts(state, data) {
      state.trendPosts = data
    },  

    setPosts(state, data) {
      state.articles = data
    },

    setTrendCount(state, data) {
      state.trendCount = data
    },

    setCount(state, data) {
      state.count = data
    },

    setTrendPage(state, data) {
      state.trendPage = data
    },

    setPage(state, data) {
      state.page = data
    },

    setCategory(state, data) {
      state.category = data
    },

    resetState(state) {
      Object.assign(state, defaultState)
    },
  },

  actions: {


    fetchTrendPosts({ commit }) {

      return new Promise((resolve) => {

        const articles = Object.values(articleData.content)
        .map(article => ({ 
          ...article, 
          key: `trend_${article.uuid}`,
        }))

        commit('setTrendPosts', [...articles])
        commit('setTrendCount', articles.length)

          // setTimeout(() => {
            console.log("mockdata : " + articleData.content.length)
            resolve(articleData)
          // }, 100)
        })
    },

    fetchPosts({ commit, state }) {

      console.log("postsData : " + postsData)
      
      const { content = [], totalElements = 0 } = postsData
          commit('setCount', totalElements)
          commit('setPosts', [
            ...state.articles, 
            ...content.map(article => ({
              ...article,
              key: `page_${article.uuid}`,
            }))
          ])
      
      return new Promise((resolve) => {
        resolve(postsData)
      })
    },

      // return httpClient.post('/articles/articlepage', { 
      //   page, 
      //   size, 
      //   categoryList: categories,
      // })
      //   .then(({ data }) => {
      //     const { content = [], totalElements = 0 } = data
      //     commit('setCount', totalElements)
      //     commit('setPosts', [
      //       ...state.articles, 
      //       ...content.map(article => ({
      //         ...article,
      //         key: `page_${article.uuid}`,
      //       }))
      //     ])
      //   })


    // },



    // fetchTrendPosts({ commit }, { categories = [] }) {
    //   return httpClient.post('/articles/trend', { categoryList: categories })
    //     .then(({ data = {} }) => {
    //       const articles = Object.values(data)
    //         .reduce((ary1, ary2) => [...ary1, ...ary2])
    //         .map(article => ({ 
    //           ...article, 
    //           key: `trend_${article.uuid}`,
    //         }))
    //       commit('setTrendPosts', [...articles])
    //       commit('setTrendCount', articles.length)
    //     })
    // },
    
    // fetchPosts({ commit, state }, { page = 0, size = 10, categories = [] }) {
    //   return httpClient.post('/articles/articlepage', { 
    //     page, 
    //     size, 
    //     categoryList: categories,
    //   })
    //     .then(({ data }) => {
    //       const { content = [], totalElements = 0 } = data
    //       commit('setCount', totalElements)
    //       commit('setPosts', [
    //         ...state.articles, 
    //         ...content.map(article => ({
    //           ...article,
    //           key: `page_${article.uuid}`,
    //         }))
    //       ])
    //     })
    // },
  },
}
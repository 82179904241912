<template>
    <div>

      <div class="FacebookTrend__title">
        <v-btn
          v-if="categoryId"
          text
          icon
          @click="goBack"
        >
          <v-icon
            dark
            left
          >
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <h1
          :class="{'pyidaungsu-bold': !!categoryId}"
        >
          {{ categoryId ? categoryDisplayName : 'Facebook Trend' }}
        </h1>
      </div>



      <PostList :articles="articleList" />
      
      <Loading v-if="loading" />

      <div v-else-if="error" class="FacebookTrend__error">
        <h2>{{ error }}</h2>
      </div>

      <div
        v-infinite-scroll="loadMore" 
        infinite-scroll-disabled="loading"
        infinite-scroll-distance="10"
      ></div>

    </div>
  </template>
  
  <script>
  import PostList from '@/components/fbtrend/PostList'
  import Loading from '@/components/Loading'
  import { mapActions, mapState, mapMutations } from 'vuex'
  import { APP_TITLE } from '@/constants'
  
  export default {
    name: 'FacebookTrend',
  
    title: 'FacebookTrend',
  
    components: {
      PostList,
      Loading,
    },
  
    data() {
      return {
        limit: 10,
        loading: false,
        error: undefined,
      }
    },
  
    mounted() {
      if (this.storeCategory !== this.categoryId) {
        this.resetState()
      }
  
      this.setCategory(this.categoryId)
  
      if (this.articleList.length === 0) {
        this.loadPosts()
      }
    },
  
    methods: {
      ...mapActions('fbtrend', [
        'fetchTrendPosts',
        'fetchPosts',
      ]),
  
      ...mapMutations('fbtrend', [
        'setPage',
        'setTrendPage',
        'setCategory',
        'resetState',
      ]),
  
      loadPosts() {
        this.loading = true
        Promise.all([
          this.fetchTrendPosts({ categories: this.categoryFilter }), 
          this.fetchPosts({ 
            page: this.page, 
            size: this.limit, 
            categories: this.categoryFilter,
          })
        ])
          .then(() => {
            this.loading = false
            this.trendCount === 0 && this.handleFetchPosts()
          })
          .catch(({ response }) => {
            this.loading = false
            if (response && response.data) {
              const { status, message } = response.data
              if (
                status === 400 &&
                message === 'Invalid category.'
              ) {
                this.error = message
              }
            } else {
              this.error = 'No Songs Found'
            }
          })
      },
  
      handleFetchPosts() {
        this.setPage(this.page + 1)
        this.loading = true
        this.fetchPosts({ 
          page: this.page, 
          size: this.limit, 
          categories: this.categoryFilter,
        })
          .then(() => this.loading = false)
      },
  
      loadMore() {
        if (this.articleList.length < this.trendCount) {
          this.setTrendPage(this.trendPage + 1)
        } else if (this.articleList.length < this.trendCount + this.count) {
          this.handleFetchPosts()
        }
      },
  
      goBack() {
        this.$router.push({ name: 'categories' })
      },
    },
  
    computed: {
      ...mapState('fbtrend', [
        'trendPosts',
        'articles',
        'trendPage',
        'page',
        'trendCount',
        'count',
      ]),
  
      ...mapState('fbtrend', {
        storeCategory: 'category',
      }),
  
      ...mapState('categories', {
        categoryList: 'list',
      }),
  
      articleList() {
        return [
          ...this.trendPosts.slice(0, this.trendPage * this.limit),
          ...this.articles.slice(0, this.page * this.limit),
        ]
      },
  
      categoryId() {
        return this.$route.params.id
      },
  
      // todo
      categoryDisplayName() {
        const category = this.categoryList.find(cat => cat.id === this.categoryId)
        document.title = `${APP_TITLE} | ${category ? category.nameMm : 'Facebook Trend'}`
        return category ? category.nameMm : ''
      },
  
      categoryFilter() {
        return this.categoryId ? [this.categoryId] : []
      },
    },
  
    watch: {
      categoryId(val) {
        this.resetState()
        this.loadPosts()
        this.setCategory(val)
      },
    },
  }
  </script>
  
  <style scoped>
  .FacebookTrend__error {
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  
  .FacebookTrend__title {
    display: flex;
    align-items: center;
  }
  </style>
  
<template>



  <div class="Login">
    <div>
      <h1 class="Login__Title">Bee News</h1>
      <br/>
      <button class="ma-2 btn-facebook" @click="fbLogin">
        <i class="fa fa-facebook mr-1"></i>
        Login with Facebook
      </button>


      <!-- <v-btn
        color="info"
        class="ma-2 Login__Button"
        depressed
        large
        :disabled="loading"
        @click="fbLogin()"
      >
        <i class="fa fa-facebook mr-1"></i>
        {{ loading ? "Loading..." : "Login With Facebook" }}
      </v-btn> -->


    </div>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Error </v-card-title>

        <v-card-text>
          {{ message }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="Aggrement">
      <router-link to="/terms-and-conditions">Terms and Conditions</router-link>
      <router-link to="/privacy-policy">Privacy Policy</router-link>
    </div>
  </div>
</template>

<style lang="scss">
.btn-facebook {
    background: #3B5998 !important;
    color: #fff;

    padding: 0 19.5555555556px;

    height: 44px;
    min-width: 78px;
    // background-color: #2196f3 !important;
    // border-color: #2196f3 !important;

    &:hover {
        color: #fff;
        opacity: 0.8;
    }
}

a { cursor: pointer }

</style>

<script>
/*global FB*/
import { fetchFbUserProfile } from "@/helpers/fb";
import { registerUser } from "@/helpers/user";

export default {
  title: "Login",

  data() {
    return {
      dialog: false,
      message: "",
      loading: false,
    };
  },


  methods: {
    async fbLogin() {
      this.loading = true;

      console.log("call facebook log in function")

      let params = { scope: 'public_profile' }

      const { authResponse, status } = await  new Promise((resolve) => {
            FB.login((response) => {
                resolve(response);
            }, params);
        });

      //new Promise(FB.login);

      if (status === "connected") {
        const { accessToken: access_token, userID: user_id } =
          authResponse;

        try {
          const {
            picture: {
              data: { url },
            },
            name,
          } = await fetchFbUserProfile(access_token);

          await registerUser({
            id: user_id,
            token: access_token,
            name,
          });

          this.$session.start();
          this.$session.set("token", access_token);
          this.$session.set("user", {
            id: user_id,
            name,
            picture: url,
          });
          this.$session.set("login_at", new Date());
          this.$router.push(this.$route.query.redirect || { name: "home" });
          this.loading = false;
        } catch (err) {
          this.handleError();
        }
    } else {
      this.handleError();
    }
      


      // await FB.login( function(response) {
      //   this.process(response)
      // }

      // async function(response) {
          // if (response.authResponse) {
          //   console.log('Welcome!  Fetching your information.... ');


            // async (response) => {

              // console.log(response.status);

          //     if (response.status === "connected") {
                
                // const { accessToken: access_token, userID: user_id } = response.authResponse;
                
                // console.log(user_id)
                // console.log(access_token)

                // try {
                //   const {
                //     picture: {
                //       data: { url },
                //     },
                //     name,
                // } = await fetchFbUserProfile(access_token);

          //       await registerUser({
          //         id: user_id,
          //         token: access_token,
          //         name,
          //       });

          //     this.$session.start();
          //     this.$session.set("token", access_token);
          //     this.$session.set("user", {
          //       id: user_id,
          //       name,
          //       picture: url,
          //     });
          //     this.$session.set("login_at", new Date());
          //     this.$router.push(this.$route.query.redirect || { name: "home" });
          //     this.loading = false;
          //   } catch (err) {
          //     this.handleError();
          //   }
          // }

            // }



          
          // } else {

          //   console.log('User cancelled login or did not fully authorize.');
          //   this.handleError();
          // }
    
        //async (res) => {
        // async function(response) {  
           // if (res.status === "connected") {
          //   const { accessToken: access_token, userID: user_id } =
          //     res.authResponse;

          //     console.log(user_id)
          //     console.log(access_token)

          //   try {
          //     const {
          //       picture: {
          //         data: { url },
          //       },
          //       name,
          //     } = await fetchFbUserProfile(access_token);

          //     await registerUser({
          //       id: user_id,
          //       token: access_token,
          //       name,
          //     });

          //     this.$session.start();
          //     this.$session.set("token", access_token);
          //     this.$session.set("user", {
          //       id: user_id,
          //       name,
          //       picture: url,
          //     });
          //     this.$session.set("login_at", new Date());
          //     this.$router.push(this.$route.query.redirect || { name: "home" });
          //     this.loading = false;
          //   } catch (err) {
          //     this.handleError();
          //   }
          // } else {
          //   this.handleError();
          // }
        // }
      //   ,
      //   { scope: "public_profile" }
      // );
    },

    handleError() {
      this.message = "Cannot connect to server. Please try again later.";
      this.dialog = true;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.Login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Login__Title {
  font-family: "Raidand";
  font-size: 48px;
}

.Aggrement {
  margin-top: 35px;
  display: flex;
  width: 300px;
  justify-content: space-between;
}
</style>
<template>
    <!-- <v-card
      outlined
      class="PostItem"
      :to="{ name: 'post-details', params: { id: article.uuid } }"
    > -->
    <v-card
      outlined
      class="PostItem"
      :href="`${article.url}`" target="_blank"
    >
      <v-list-item class="grow">
        <v-list-item-avatar
          v-if="article.thumbnailUrl"
          size="80"
          tile
          color="grey"
        >
          <v-img
            class="elevation-6"
            alt=""
            :src="article.thumbnailUrl"
          ></v-img>
        </v-list-item-avatar>
  
        <v-list-item-content>
          <v-list-item-title
            class="PostItem__title pyidaungsu"
          >
            {{ article.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <span 
              class="PostItem__host"
            >
              {{ article.host }} | 
            </span>
            <span 
              class="PostItem__time"
            >
              {{ article.publishedTime | relativeTime }}
            </span>

            <br />
<!-- 
            <span>
              <v-icon class="mr-1 icon-size">mdi-thumb-up</v-icon>
            </span> -->


            <span class="caption mr-1">
                <v-icon class="mr-1 icon-size" color="#929bae">mdi-thumb-up</v-icon>
                  <!-- <v-icon class="icon-size" color="#929bae">mdi-thumbs-up</v-icon> -->
                  <span class="post-reaction--customize">
                    {{ article.like }} 
                  </span>
            </span>

            <span class="caption mr-1">
                  <v-icon class="icon-size" color="#929bae">mdi-comment</v-icon>
                  <span class="post-reaction--customize">
                    {{ article.comment }} 
                  </span>
              </span>
            <span class="caption">
                <v-icon class="icon-size" color="#929bae">mdi-share</v-icon>
                <span class="post-reaction--customize">
                  {{ article.share }} 
                </span>
            </span>


          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </template>
  
  <script>

  import moment from 'moment'
  
  export default {
    name: 'PostItem',
  
    props: [
      'article',
    ],
  
    filters: {
      relativeTime(datetime) {
        return moment(datetime).fromNow()
      }
    },
  }
  </script>
  
  <style scoped>
  .PostItem:not(:first-child) {
    margin-top: 8px;
  }
  
  .PostItem__title {
    line-height: 1.5 !important;
    white-space: normal !important;
  }
  
  .PostItem__category {
    font-weight: bold;
  }
  
  .PostItem__time {
    font-style: italic;
    font-size: 13px;
  }
  
  .PostItem__host {
    font-size: 13px;
  }

.post-reaction--customize{
    font-size: 12px;
    display:inline-block;
    margin-left: 4px;
    color: #929bae;
}

  i.icon-size{
        font-size: 12px;
  }
  </style>
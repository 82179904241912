<template>
    <div class="PostList">
      <PostItem
        v-for="article of articles"
        :key="article.key"
        :article="article"
      />
    </div>
  </template>
  
  <script>
  import PostItem from '@/components/fbtrend/PostItem'
  
  export default {
    name: 'PostList',
  
    props: [
      'articles',
    ],
  
    components: {
      PostItem,
    },
  }
  </script>
  
  <style scoped>
  </style>
<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row
          class="justify-center"
          no-gutters
        >
          <v-col 
            sm="12" 
            md="4" 
            lg="3"
            class="d-none d-md-flex"
          >
            <div
              class="SideNavigation"
            >
              <h1 
                class="SideNavigation__AppTitle"
              >
                Bee News
              </h1>
              <v-list
                app
                nav
                dense
                class="SideNavigation__List"
              >
                <v-list-item-group
                  color="primary"
                  multiple
                >
                  <v-list-item exact :to="{ name: 'home' }">
                    <v-list-item-icon>
                      <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Home</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>


                  <v-list-item exact :to="{ name: 'fbtrend' }">
                    <v-list-item-icon>
                      <v-icon>mdi-facebook</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Facebook Trend</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>


                  <v-list-group
                    no-action
                  >
                    <template v-slot:activator>
                      <v-list-item-icon>
                        <v-icon>mdi-magnify</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Categories</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="category in list"
                      :key="category.id"
                      exact
                      :to="{ name: 'bycategory', params: { id: category.id } }"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          class="SideNavigation__CategoryItem"
                        >
                          {{ category.nameMm }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>

                  <v-list-item exact :to="{ name: 'setting' }">
                    <v-list-item-icon>
                      <v-icon>mdi-cog</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Setting</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>


                </v-list-item-group>
              </v-list>
            </div>
          </v-col>
          <v-col sm="10" md="8">
            <router-view/>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog
        v-model="dialog"
        max-width="290"
      >
        <v-card>
          <v-card-title class="headline">
            Error
          </v-card-title>

          <v-card-text>
            {{ error.message }}
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialogAction"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>

    <v-bottom-navigation 
      class="d-flex d-md-none"
      color="primary"
      shift
      grow
      app
    >

      <v-btn 
        class="BottomNavigation__Btn"
        value="home"
        exact
        :to="{ name: 'home' }"
      >
        <span>Home</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn 
        class="BottomNavigation__Btn"
        value="fbtrend"
        exact
        :to="{ name: 'fbtrend' }"
      >
        <span>Facebook Trend</span>
        <v-icon>mdi-facebook</v-icon>
      </v-btn>


      <v-btn 
        class="BottomNavigation__Btn"
        value="categories"
        exact
        :to="{ name: 'categories' }"
      >
        <span>Categories</span>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn 
        class="BottomNavigation__Btn"
        value="setting"
        exact
        :to="{ name: 'setting' }"
      >
        <span>Setting</span>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-bottom-navigation>

  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  data() {
    return {
      dialog: false,
    }
  },

  computed: {
    ...mapState([
      'error',
    ]),

    ...mapState('categories', [
      'list',
      'loading',
    ]),
  },

  created() {
    if (this.list.length === 0) {
      this.setLoading(true)
      this.fetchCategories()
        .then(() => this.setLoading(false))
    }
  },

  methods: {
    ...mapActions('categories', [
      'fetchCategories',
    ]),

    ...mapMutations('categories', [
      'setLoading',
    ]),

    ...mapActions([
      'resetAllStates',
    ]),

    dialogAction() {
      const { status } = this.error
      if (status === 401) {
        this.$session.destroy()
        this.$router.push({ name: 'login' })
        this.resetAllStates()
      }
      this.dialog = false
    },
  },

  watch: {
    error(val) {
      this.dialog = !!val
    }
  }
}
</script>

<style scoped>
.SideNavigation {
  position: fixed;
  min-width: 250px;
  overflow: scroll;
  height: 100%;
}

.BottomNavigation__Btn {
  height: 56px !important;
}

.SideNavigation__AppTitle {
  font-family: 'Raidand';
  text-align: center;
  font-size: 40px;
}

.SideNavigation__CategoryItem {
  font-family: 'Pyidaungsu';
  line-height: 1.8 !important;
}

.SideNavigation__List {
  background: transparent !important;
  margin-bottom: 22px;
}
</style>
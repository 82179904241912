import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ArticleDetails from "../views/ArticleDetails.vue";
import Login from "../views/Login.vue";

import FbTrend from "../views/FbTrend.vue";
import PostDetails from "../views/PostDetails.vue";

import Categories from "../views/Categories.vue";
import Setting from "../views/Setting.vue";
import TermsAndConditions from "../views/TermsAndConditions.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import { getSession } from "../helpers/session";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/articles/:id",
    name: "article-details",
    component: ArticleDetails,
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      layout: "EmptyLayout",
    },
  },
  {
    path: "/fbtrend",
    name: "fbtrend",
    component: FbTrend,
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/posts/:id",
    name: "post-details",
    component: PostDetails,
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/categories",
    name: "categories",
    component: Categories,
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/categories/:id",
    name: "bycategory",
    component: Home,
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/setting",
    name: "setting",
    component: Setting,
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: TermsAndConditions,
    meta: { layout: "EmptyLayout" },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    meta: { layout: "EmptyLayout" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const session = getSession();
  const isLoginPage = to.name === "login";
  const isPublicRoutes =
    to.name === "terms-and-conditions" || to.name === "privacy-policy";
  const isLoggedIn = !!session && !!session.token;

  if (!isLoggedIn && !isLoginPage) {
    if (isPublicRoutes) return next();
    return next({
      name: "login",
      query: { redirect: to.fullPath },
    });
  }

  if (isLoggedIn && isLoginPage) {
    return next({
      name: "home",
    });
  }

  next();
});

export default router;
